<template>
  <div>
    <b>{{ item.postal }}:</b><span> {{ item.name }}</span>
  </div>
</template>

<script>
  export default {
    name: 'AutocompleteItem',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>
