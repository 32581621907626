<template>
  <div>
    <b-form id="prospectintro" @submit.prevent>
      <b-row class="form-section">
        <b-col>
          <p>
            {{ trans('prospects.landingpage.intro_block.projectinfo') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('global.name') }}
              </label>
            </b-col>
            <b-col cols="6">
              <b-form-input
                id="prospect-name"
                v-model="formData.name"
                :placeholder="trans('prospects.landingpage.intro_block.name_placeholder')"
              >
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label
                for="prospect-postal"
                class="font-weight-normal"
              >
                {{ trans('global.postal') }}
              </label>
            </b-col>
            <b-col cols="6">
              <Autocomplete
                id="prospect-postal"
                v-model="formData.postal"
                :items="inputItems"
                :item-template="AutocompleteItem"
                input-classes="form-control"
                @onInputChange="autocompleteInputChange"
                @onItemSelected="autocompleteItemSelected"
              >
              </Autocomplete>
            </b-col>
          </b-row>
          <b-form-group
            label-cols="2"
            :label="trans('global.salestext')"
            label-for="prospect-salestext"
            class="font-weight-normal"
          >
            <b-form-textarea
              id="pospect-salestext"
              v-model="formData.salestext"
              class="col-6"
              :placeholder="trans('prospects.landingpage.intro_block.salestext_placeholder')"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="form-section">
        <b-col>
          <p>
            {{ trans('prospects.landingpage.intro_block.projectpricing') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('prospects.landingpage.intro_block.price') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-price"
                v-model="formData.price"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('prospects.landingpage.intro_block.publicdebt') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-publicdebt"
                v-model="formData.publicdebt"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('prospects.landingpage.intro_block.costs') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-costs"
                v-model="formData.costs"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label for="prospect-totalprice" class="font-weight-normal">
                {{ trans('prospects.landingpage.intro_block.totalprice') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-totalprice"
                v-model="totalPrice"
                disabled
              >
              </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="form-section">
        <b-col>
          <p>
            {{ trans('prospects.landingpage.intro_block.projectdetails') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="alert alert-info">
            <i class="fal fa-info-circle padding-right-6"></i>
            {{ trans('prospects.landingpage.intro_block.description') }}
          </div>
        </b-col>
        <b-col cols="6">
          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.address') }}
              </label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                id="prospect-details.address"
                v-model="formData.details.address"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.floor') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.floor"
                v-model="formData.details.floor"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.built') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.built"
                v-model="formData.details.built"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.bedrooms') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.bedrooms"
                v-model="formData.details.bedrooms"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.type') }}
              </label>
            </b-col>
            <b-col cols="6">
              <b-form-select
                id="prospect-details-type"
                v-model="formData.details.type"
                name="project-details-type"
                class="form-control-lg col-8"
              >
                <option :value="null">
                  {{ trans('global.choose') }}
                </option>
                <option
                  v-for="(type, index) in projectTypes"
                  :key="index"
                  :value="index"
                >
                  {{ trans('global.' + type) }}
                </option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.ownershiptype') }}
              </label>
            </b-col>
            <b-col cols="6">
              <b-form-select
                id="prospect-details-ownershiptype"
                v-model="formData.details.ownershiptype"
                name="project-details-ownershiptype"
                class="form-control-lg col-8"
              >
                <option :value="null">
                  {{ trans('global.choose') }}
                </option>
                <option
                  v-for="(ownershiptype, index) in ownershipTypes"
                  :key="index"
                  :value="index"
                >
                  {{ trans('global.' + ownershiptype) }}
                </option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.energy') }}
              </label>
            </b-col>
            <b-col cols="6">
              <b-form-select
                id="prospect-details-energy"
                v-model="formData.details.energy"
                name="project-details-energy"
                class="form-control-lg col-8"
              >
                <option :value="null">
                  {{ trans('global.choose') }}
                </option>
                <option
                  v-for="(energy, index) in energyTypes"
                  :key="index"
                  :value="index"
                >
                  {{ energy.toUpperCase() }}
                </option>
              </b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.primaryspace') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.primaryspace"
                v-model="formData.details.primaryspace"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.secondaryspace') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.secondaryspace"
                v-model="formData.details.secondaryspace"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.usagearea') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.usagearea"
                v-model="formData.details.usagearea"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.publicexpenses') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.publicexpenses"
                v-model="formData.details.publicexpenses"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.rooms') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.rooms"
                v-model="formData.details.rooms"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.grossarea') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.grossarea"
                v-model="formData.details.grossarea"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="3">
              <label
                class="font-weight-normal"
              >
                {{ trans('prospects.landingpage.intro_block.details.plotarea') }}
              </label>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="prospect-details.plotarea"
                v-model="formData.details.plotarea"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button v-if="!saveInProgress" ref="introblockSaveButton" variant="primary" class="margin-top-26 mx-auto my-5" @click="save($event)">
            {{ trans('prospects.landingpage.intro_block.save') }}
          </b-button>
          <b-button v-else variant="primary" class="margin-top-26 mx-auto my-5" :style="{ width: saveButtonWidth }">
            <b-spinner variant="white" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import AutocompleteItem from '../../../../../../Core/Forms/AutocompleteItem'

  const fb = require('../../../../../../../firebaseConfig')
  const varyConfig = require('../../../../../../../varyConfig')

  const Autocomplete = () => import('../../../../../../Core/Forms/Autocomplete')

  export default {
    name: 'LandingpageBlockCarousel',
    components: {
      Autocomplete
    },
    props: {
      block: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        formData: {
          name: this.block.data !== undefined && this.block.data.name !== undefined ? this.block.data.name : '',
          postal: this.block.data !== undefined && this.block.data.postal !== undefined ? this.block.data.postal : null,
          salestext: this.block.data !== undefined && this.block.data.salestext !== undefined ? this.block.data.salestext : '',
          price: this.block.data !== undefined && this.block.data.price !== undefined ? this.block.data.price : '',
          publicdebt: this.block.data !== undefined && this.block.data.publicdebt !== undefined ? this.block.data.publicdebt : '',
          costs: this.block.data !== undefined && this.block.data.costs !== undefined ? this.block.data.costs : '',
          totalprice: !isNaN(this.totalPrice) ? this.totalPrice : 0,
          area: this.block.data !== undefined && this.block.data.area !== undefined ? this.block.data.area : '',
          details: {
            floor: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.floor !== undefined ? this.block.data.details.floor : '',
            built: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.built !== undefined ? this.block.data.details.built : '',
            type: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.type !== undefined ? this.block.data.details.type : '',
            bedrooms: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.bedrooms !== undefined ? this.block.data.details.bedrooms : '',
            address: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.address !== undefined ? this.block.data.details.address : '',
            ownershiptype: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.ownershiptype !== undefined ? this.block.data.details.ownershiptype : null,
            energy: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.energy !== undefined ? this.block.data.details.energy : null,
            primaryspace: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.primaryspace !== undefined ? this.block.data.details.primaryspace : '',
            secondaryspace: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.secondaryspace !== undefined ? this.block.data.details.secondaryspace : '',
            usagearea: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.usagearea !== undefined ? this.block.data.details.usagearea : '',
            publicexpenses: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.publicexpenses !== undefined ? this.block.data.details.publicexpenses : '',
            rooms: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.rooms !== undefined ? this.block.data.details.rooms : '',
            grossarea: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.grossarea !== undefined ? this.block.data.details.grossarea : '',
            plotarea: this.block.data !== undefined && this.block.data.details !== undefined && this.block.data.details.plotarea !== undefined ? this.block.data.details.plotarea : ''
          }
        },
        projectTypes: varyConfig.projectTypes,
        ownershipTypes: varyConfig.ownershipTypes,
        energyTypes: varyConfig.energyTypes,
        postalItems: varyConfig.postalCodes,
        inputItems: [],
        AutocompleteItem,
        saveInProgress: false,
        saveButtonWidth: false
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      }),
      totalPrice () {
        return parseInt(this.formData.price) + parseInt(this.formData.publicdebt) + parseInt(this.formData.costs)
      }
    },
    methods: {
      autocompleteItemSelected (item) {
        this.postal = item
      },
      autocompleteInputChange (text) {
        this.inputItems = this.postalItems.filter(item => item.postal.includes(text) || item.name.toLowerCase().includes(text.toLowerCase()))
      },
      save () {
        const self = this
        this.saveButtonWidth = this.$refs.introblockSaveButton ? (parseInt(this.$refs.introblockSaveButton.clientWidth) + 2).toString() + 'px' : false
        this.saveInProgress = true

        this.checkIfBlockExistsInDB().then(() => {
          fb.prospectsCollection.doc(this.prospect._id).update({
            [`landingpage.blocks.${this.block.key}.data`]: this.formData
          }).then(() => {
            self.$bvToast.toast(self.trans('prospects.landingpage.intro_block.save_success'), {
              title: self.trans('global.success'),
              variant: 'success',
              solid: true
            })
            this.saveInProgress = false
          }).catch((err) => {
            self.$bvToast.toast(self.trans('prospects.landingpage.intro_block.save_error'), {
              title: self.trans('global.error'),
              variant: 'warning',
              solid: true
            })
            console.log('There was an error while saving the info for the Intro-block to the DB:')
            console.log(err)
            this.saveInProgress = false
          })
        })
      },
      // checkIfBlockExistsInDB (): "Standard" / Required blocks may not be in DB upon creation...
      checkIfBlockExistsInDB () {
        return new Promise((resolve, reject) => {
          if (this.block.key !== undefined) {
            resolve(true)
          } else {
            const nextBlockId = this.prospect.landingpage !== undefined &&
              this.prospect.landingpage.blocks !== undefined &&
              Object.keys(this.prospect.landingpage.blocks).length > 0
              ? parseInt(Object.keys(this.prospect.landingpage.blocks).pop()) + 1
              : 0

            return fb.prospectsCollection.doc(this.prospect._id).update({
              [`landingpage.blocks.${nextBlockId}`]: {
                createdOn: new Date(),
                deleted: false,
                name: this.block.name.charAt(0).toUpperCase() + this.block.name.slice(1), // Ensure correct naming (camel)
                sortable: false,
                actions: false,
                userId: this.userProfile.uid
              }
            }).then(() => {
              resolve()
            }).catch((err) => {
              reject(err)
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #prospectintro {
    .row {
      .center-text {
        align-items: center;

        label {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
